import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({
	customButton: {
		fontWeight: 'bold',
		fontSize: '11px',
		minWidth: '150px',
		height: '38px',
		/* boxShadow: '0px 0px 5px 0.7px grey', */
		fontFamily: 'Lucida-Regular-Bold',
	},
}));

const CustomButton = (props) => {
	const classes = useStyles();
	return (
		<Button
			{...props}
			className={classes.customButton}
			variant="contained"
			style={
				(props.size === 'medium'
					? {
							width: '310px',
					  }
					: { width: '320px' },
				props.color === 'primary'
					? {
							backgroundColor: APP_CONFIG.mainCollors.primary,
							color: '#fff',
					  }
					: { color: '#000' })
			}
		>
			<Typography
				style={{
					fontSize: '16px',
				}}
			>
				{props.children}
			</Typography>
		</Button>
	);
};

export default CustomButton;
