import {
	Box,
	Grid,
	IconButton,
	makeStyles,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import CustomButton from '../../components/CustomButton/CustomButton';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { APP_CONFIG } from '../../constants/config';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link, useParams, useHistory, generatePath } from 'react-router-dom';
import { Divider } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GoogleMapReact from 'google-map-react';
import emailjs from '@emailjs/browser';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: APP_CONFIG.mainCollors.backgrounds,
	},
	inputRoot: {
		'& input': {
			paddingTop: 0, // Adjust this value to move the placeholder text up
		},
	},
}));

const Dashboard = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const form = useRef();
	const theme = useTheme();
	const matches = useMediaQuery('(max-width:1120px)');
	const matchesTextos = useMediaQuery('(max-width:1400px)');
	const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'));
	const firstRef = useRef(null);
	const secondRef = useRef(null);
	const thirdRef = useRef(null);
	const fourthRef = useRef(null);
	const firstScroll = () => firstRef.current.scrollIntoView();
	const secondScroll = () => secondRef.current.scrollIntoView();
	const thirdScroll = () => thirdRef.current.scrollIntoView();
	const fourthScroll = () => fourthRef.current.scrollIntoView();

	const handleEnviar = (e) => {
		e.preventDefault();
		console.log(form);

		emailjs
			.sendForm(
				'service_jhlpskl',
				'template_4imshp8',
				form.current,
				'zOqQw82g3f7CUQLUL'
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	//cosmocontabilidade

	/* const handleEnviar = (e) => {
		e.preventDefault();
		console.log(form);

		emailjs
			.sendForm(
				'service_66k0k34',
				'template_rag9o51',
				form.current,
				'HbXqjcO313bTxiIEu'
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	}; */
	//teste local

	const defaultProps = {
		center: {
			lat: -16.732960436894135,
			lng: -49.26833427744318,
		},
		zoom: 11,
	};

	const AnyReactComponent = ({ text }) => <div>{text}</div>;

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<Box
				style={{
					position: matches ? 'unset' : 'sticky',
					width: '100%',
					top: 0,
					backgroundColor: APP_CONFIG.mainCollors.backgrounds,
					zIndex: 1000,
					maxWidth: '100%',
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '10px',
						height: '20px',
						backgroundColor: APP_CONFIG.mainCollors.backgrounds,
						padding: '20px',
					}}
				>
					<Box>
						<img
							src={APP_CONFIG.assets.logo}
							alt="logo"
							style={{ width: '200px' }}
						/>
					</Box>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							minWidth: '100px',
						}}
					>
						{matches ? null : (
							<LocalPhoneOutlinedIcon
								style={{
									marginRight: '5px',
									color: APP_CONFIG.mainCollors.primary,
								}}
							/>
						)}

						<Typography
							style={{
								color: APP_CONFIG.mainCollors.primary,
								fontFamily: 'Lucida-Regular',
								fontSize: matches ? '12px' : '20px',
								marginLeft: matches ? '12px' : '0px',
							}}
						>
							{APP_CONFIG.telefone}
						</Typography>
					</Box>
					{matches ? null : (
						<>
							<Box
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									minWidth: '150px',
								}}
							>
								<MailOutlineIcon
									style={{
										marginRight: '5px',
										color: APP_CONFIG.mainCollors.primary,
									}}
								/>
								<Typography
									style={{
										color: APP_CONFIG.mainCollors.primary,
										fontFamily: 'Lucida-Regular',
										lineBreak: 'anywhere',
									}}
								>
									{APP_CONFIG.email}
								</Typography>
							</Box>
							<Box
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									minWidth: '150px',
								}}
							>
								<WatchLaterOutlinedIcon
									style={{
										marginRight: '5px',
										color: APP_CONFIG.mainCollors.primary,
									}}
								/>
								<Typography
									style={{
										color: APP_CONFIG.mainCollors.primary,
										fontFamily: 'Lucida-Regular',
									}}
								>
									{APP_CONFIG.horario}
								</Typography>
							</Box>
						</>
					)}

					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<IconButton
							component={Link}
							to={{ pathname: APP_CONFIG.linkInstagram }}
							target="_blank"
						>
							<InstagramIcon
								style={{
									fontSize: '50px',
									color: APP_CONFIG.mainCollors.primary,
								}}
							/>
						</IconButton>
					</Box>
					{matches ? null : (
						<>
							<Box>
								<CustomButton color="primary" onClick={fourthScroll}>
									<Typography style={{ fontFamily: 'Corbel-Bold' }}>
										TRAGA A SUA EMPRESA
									</Typography>
								</CustomButton>
							</Box>
						</>
					)}
				</Box>
				<Divider
					style={{
						marginTop: '10px',
						width: '100%',
						height: '0.01rem',
						backgroundColor: APP_CONFIG.mainCollors.primary,
					}}
				/>
			</Box>
			<Box
				style={{
					backgroundColor: APP_CONFIG.mainCollors.backgrounds,
					width: '100%',
					alignSelf: 'center',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '50%',
						alignSelf: 'center',
						height: '63px',
						alignItems: 'center',
						minWidth: '380px',
					}}
				>
					<Box onClick={firstScroll} component={Link}>
						<Typography
							style={{
								color: APP_CONFIG.mainCollors.primary,
								fontFamily: 'Corbel-Bold',
							}}
						>
							HISTÓRIA
						</Typography>
					</Box>
					<Box onClick={secondScroll} component={Link}>
						<Typography
							style={{
								color: APP_CONFIG.mainCollors.primary,
								fontFamily: 'Corbel-Bold',
							}}
						>
							SERVIÇOS
						</Typography>
					</Box>
					<Box onClick={thirdScroll} component={Link}>
						<Typography
							style={{
								color: APP_CONFIG.mainCollors.primary,
								fontFamily: 'Corbel-Bold',
							}}
						>
							INSTITUCIONAL
						</Typography>
					</Box>
					<Box onClick={fourthScroll} component={Link}>
						<Typography
							style={{
								color: APP_CONFIG.mainCollors.primary,
								fontFamily: 'Corbel-Bold',
							}}
						>
							CONTATO
						</Typography>
					</Box>
				</Box>
			</Box>
			<Carousel showThumbs={false} emulateTouch infiniteLoop={true} autoPlay>
				<Box>
					<img src={APP_CONFIG.assets.slider1} />
				</Box>
				<Box>
					<img src={APP_CONFIG.assets.slider2} />
				</Box>
				<Box>
					<img src={APP_CONFIG.assets.slider3} />
				</Box>
				<Box>
					<img src={APP_CONFIG.assets.slider4} />
				</Box>
				<Box>
					<img src={APP_CONFIG.assets.slider5} />
				</Box>
				<Box>
					<img src={APP_CONFIG.assets.slider6} />
				</Box>
			</Carousel>

			<Box
				ref={firstRef}
				style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				<img
					src={APP_CONFIG.assets.background1}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.tertiary,
						}}
					>
						NOSSA HISTÓRIA
					</Typography>
					<Box
						style={{
							display: 'flex',
							alignSelf: 'center',
							width: '70%',
							marginTop: matches ? '12px' : '30px',
						}}
					>
						<Typography
							style={{
								fontFamily: 'Lucida-Regular',
								color: APP_CONFIG.mainCollors.primary,
								fontSize: matches ? '8px' : '20px',
								textAlign: 'justify',
							}}
						>
							A jornada da Cosmo Contabilidade teve início em junho de
							1990, quando o casal Cosmo e Sandra, então estudantes do
							curso de Ciências Contábeis na PUC Goiás, combinaram seus
							esforços e vislumbraram um crescimento notável no campo
							contábil. A Cosmo Contabilidade nasceu da convicção mútua e
							da ambição conjunta de criar algo significativo. No início,
							uma única máquina de datilografia e duas mesas foram
							suficientes para dar o pontapé inicial.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				ref={secondRef}
				style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				<img
					src={APP_CONFIG.assets.background3}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.secondary,
						}}
					>
						SERVIÇOS
					</Typography>
					{matchesTextos ? null : (
						<Box
							style={{
								display: 'flex',
								alignSelf: 'center',
								maxWidth: '70%',
								marginTop: matches ? '4px' : '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Lucida-Regular',
									color: '#fff',
									textAlign: 'justify',
									fontSize: matches ? '8px' : '20px',
								}}
							>
								Ao longo dos anos, à medida que a Cosmo Contabilidade
								expandia sua atuação, ela passou a abranger diversos
								segmentos contábeis, englobando Comércio, Indústria,
								Condomínios, Agronegócio e Prestação de Serviços. Nossos
								serviços englobam áreas como Departamento Pessoal,
								Escrita Fiscal, Contabilidade e Assessoria Jurídica,
								abarcando a legislação fiscal, societária e trabalhista.
								Realizamos apurações tributárias para regime de Lucro
								Real, Presumido, Simples Nacional e MEI
								(Microempreendedor Individual).
							</Typography>
						</Box>
					)}

					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyItems: 'center',
						}}
					>
						<Grid
							spacing={3}
							style={{
								marginTop: matches ? '20px' : '50px',
								display: 'flex',
							}}
						>
							<Grid item sm={4} xs={12} style={{ marginRight: '4px' }}>
								<Box
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: matches ? '125px' : '325px',
										height: matches ? '80px' : '200px',

										borderRadius: 17,
										backgroundColor: APP_CONFIG.mainCollors.cards,
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Box>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',
												fontSize: matches ? '8px' : '20px',
												color: APP_CONFIG.mainCollors.secondary,
											}}
										>
											ABERTURA DE EMPRESAS
										</Typography>
									</Box>
									<Box style={{ width: '87%' }}>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',
												fontSize: matches ? '5px' : '12px',
												color: '#fff',
												marginTop: matches ? '8px' : '20px',
												textAlign: 'center',
											}}
										>
											Cuidamos de todo o processo legal e
											administrativo na abertura de uma empresa,
											registrando nos órgãos competentes e cumprindo
											requisitos legais, fiscais e burocráticos para
											operar legalmente no mercado.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item sm={4} xs={12} style={{ marginRight: '4px' }}>
								<Box
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: matches ? '125px' : '325px',
										height: matches ? '80px' : '200px',
										borderRadius: 17,
										backgroundColor: APP_CONFIG.mainCollors.cards,
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Box>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',
												fontSize: '20px',
												fontSize: matches ? '8px' : '20px',
												color: APP_CONFIG.mainCollors.secondary,
											}}
										>
											ASSESSORIA CONTÁBIL
										</Typography>
									</Box>
									<Box style={{ width: '87%' }}>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',
												fontSize: matches ? '5px' : '12px',
												color: '#fff',
												marginTop: matches ? '8px' : '20px',
												textAlign: 'center',
											}}
										>
											Auxiliamos empresas e indivíduos na gestão de
											suas finanças, cumprimento de obrigações
											contábeis exigidos pela legislação e na tomada
											de decisões financeiras estratégicas, visando a
											otimização de recursos e o cumprimento das
											normas regulatórias.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Box
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: matches ? '125px' : '325px',
										height: matches ? '80px' : '200px',
										borderRadius: 17,
										backgroundColor: APP_CONFIG.mainCollors.cards,
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Box>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',

												fontSize: matches ? '8px' : '20px',
												color: APP_CONFIG.mainCollors.secondary,
											}}
										>
											ASSESSORIA TRIBUTÁRIA
										</Typography>
									</Box>
									<Box style={{ width: '87%' }}>
										<Typography
											style={{
												fontFamily: 'Lucida-Regular',
												fontSize: matches ? '5px' : '12px',
												color: '#fff',
												marginTop: matches ? '8px' : '20px',
												textAlign: 'center',
											}}
										>
											Fazemos uma análise profunda de empresas e
											pessoas e orientamos a entender e cumprir suas
											obrigações fiscais, otimizando a carga
											tributária por meio de estratégias legais,
											reduzindo custos e minimizando riscos de
											conformidade com as leis tributárias.
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>

						<Box
							style={{
								display: 'flex',
								width: '100%',
								alignSelf: 'center',
								justifyContent: 'center',
								marginTop: matches ? '8px' : '20px',
							}}
						>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '130px' : '325px',
									height: matches ? '80px' : '200px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
									marginRight: matches ? '4px' : '10px',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',

											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										ASSESSORIA TRABALISTA
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Cuidamos de todas as questões administrativas
										relacionadas aos funcionários, incluindo
										admissões, demissões, folha de pagamento,
										benefícios, registros de ponto e cumprimento das
										obrigações trabalhistas e previdenciárias. Estamos
										preparados a atender todos os pontos do eSocial.
									</Typography>
								</Box>
							</Box>

							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '130px' : '325px',

									height: matches ? '80px' : '200px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',

											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										REGULAMENTAÇÃO DE CNPJ
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Realizamos uma vasta vistoria no seu CNPJ e
										detectamos todas as pendências como débitos
										fiscais, informações cadastrais desatualizadas ou
										omissões, para garantir que a empresa esteja em
										conformidade com as obrigações tributárias e
										cadastrais perante a Receita Federal, permitindo
										seu funcionamento regular.
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				<img
					src={APP_CONFIG.assets.background1}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.tertiary,
						}}
					>
						PARCEIROS
					</Typography>
					{matchesTextos ? null : (
						<Box
							style={{
								display: 'flex',
								alignSelf: 'center',
								width: '70%',
								marginTop: '30px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Lucida-Regular',
									color: APP_CONFIG.mainCollors.primary,
									fontSize: matches ? '8px' : '20px',
									textAlign: 'justify',
								}}
							>
								Contamos com parcerias valiosas, tais como Onix Hotel,
								Izuka Odontologia, Decoarte Decoração, Metropole
								Representação, Pedreira Izaira, Pedreira Araguaia,
								Compel Explosivos, Ligmobile Telecomunicações,
								Laboratório Biovet, Laboratório Labovet, ECO Sondagem,
								Mineração Gever, Gever Agropecuária, Mineração Jandaia,
								Univence Construtora, Mural Engenharia, Prospector
								Minerals, Real Formulários, Warre Engenharia, Transpel
								Transportadora, BR Wave Telecomunicações, 3 P
								Empreendimentos Imobiliários, Mega Representação,
								Copicenter Serviços de Fotocópias, além dos condomínios
								Green Hills, Marrom, Cerro Azul, Aurora Rassi, Cardeal,
								entre outros.
							</Typography>
						</Box>
					)}

					<Box
						style={{
							marginTop: matches ? '24px' : '60px',
							width: '70%',
						}}
					>
						<Carousel
							showThumbs={false}
							emulateTouch
							infiniteLoop={true}
							autoPlay
						>
							<Box>
								<img src={APP_CONFIG.assets.logoParceiro1} />
							</Box>
							<Box>
								<img src={APP_CONFIG.assets.logoParceiro2} />
							</Box>
							<Box>
								<img src={APP_CONFIG.assets.logoParceiro3} />
							</Box>
							<Box>
								<img src={APP_CONFIG.assets.logoParceiro4} />
							</Box>
						</Carousel>
					</Box>
				</Box>
			</Box>
			<Box
				ref={thirdRef}
				style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				<img
					src={APP_CONFIG.assets.background2}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.secondary,
						}}
					>
						VALORES
					</Typography>
					<Box
						style={{
							display: 'flex',
							alignSelf: 'center',

							marginTop: '10px',
						}}
					>
						<Typography
							style={{
								fontFamily: 'Lucida-Regular',
								color: '#fff',
								textAlign: 'center',
								fontSize: matches ? '8px' : '20px',
							}}
						>
							Os valores que norteiam nossas ações e decisões são
							fundamentais para nós.
						</Typography>
					</Box>

					<Grid
						spacing={3}
						style={{
							marginTop: matches ? '20px' : '50px',
							display: 'flex',
						}}
					>
						<Grid item sm={4} xs={12} style={{ marginRight: '10px' }}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										TRANSPARÊNCIA
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Operamos de maneira aberta e honesta, fortalecendo
										relações baseadas na confiança.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item sm={4} xs={12} style={{ marginRight: '10px' }}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										COMPROMETIMENTO
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Nos dedicamos intensamente a superar as
										expectativas dos clientes, demonstrando nosso
										compromisso genuíno.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item sm={4} xs={12}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										PROFISSIONALISMO
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Mantemos os mais altos padrões de profissionalismo
										em cada interação e serviço prestado.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

					<Grid
						spacing={3}
						style={{
							marginTop: matches ? '8px' : '20px',
							display: 'flex',
						}}
					>
						<Grid item sm={4} xs={12} style={{ marginRight: '10px' }}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										CONFIANÇA
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Construímos e preservamos a confiança, sendo
										consistentes em nossa entrega e responsabilidade.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item sm={4} xs={12} style={{ marginRight: '10px' }}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										RESOLUTIVIDADE
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Encaramos desafios como oportunidades, agindo com
										agilidade e encontrando soluções eficazes.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item sm={4} xs={12}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: matches ? '100px' : '250px',
									height: matches ? '72px' : '180px',
									borderRadius: 17,
									backgroundColor: APP_CONFIG.mainCollors.cards,
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '8px' : '20px',
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										EXPERIÊNCIA
									</Typography>
								</Box>
								<Box style={{ width: '87%' }}>
									<Typography
										style={{
											fontFamily: 'Lucida-Regular',
											fontSize: matches ? '5px' : '12px',
											color: '#fff',
											marginTop: matches ? '8px' : '20px',
											textAlign: 'center',
										}}
									>
										Baseamos nossas ações em anos de expertise,
										oferecendo insights e orientações valiosas.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box
				style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				<img
					src={APP_CONFIG.assets.background1}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.tertiary,
						}}
					>
						PROPÓSITO
					</Typography>
					<Box
						style={{
							display: 'flex',
							alignSelf: 'center',
							width: '70%',
							marginTop: matches ? '4px' : '30px',
						}}
					>
						<Typography
							style={{
								fontFamily: 'Lucida-Regular',
								color: APP_CONFIG.mainCollors.primary,
								textAlign: 'center',
								fontSize: matches ? '8px' : '20px',
							}}
						>
							Auxiliar os clientes a alcançar seus objetivos e otimizar
							seus resultados por meio de soluções personalizadas e
							adaptadas às suas necessidades específicas.
						</Typography>
					</Box>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							marginTop: matches ? '5px' : '50px',
							color: APP_CONFIG.mainCollors.tertiary,
						}}
					>
						MISSÃO
					</Typography>
					<Box
						style={{
							display: 'flex',
							alignSelf: 'center',
							width: '70%',
							marginTop: matches ? '4px' : '30px',
						}}
					>
						<Typography
							style={{
								fontFamily: 'Lucida-Regular',
								color: APP_CONFIG.mainCollors.primary,
								textAlign: 'center',
								fontSize: matches ? '8px' : '20px',
							}}
						>
							Estamos profundamente comprometidos e qualificados para
							fornecer serviços de excelência, atendendo de maneira
							eficiente, eficaz e transparente às demandas dos nossos
							clientes.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<img
					src={APP_CONFIG.assets.background4}
					style={{ width: '100%', position: 'relative' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'absolute',
						alignSelf: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.secondary,
						}}
					>
						LOCALIZAÇÃO
					</Typography>

					<Box
						style={{
							display: 'flex',

							width: '100%',
							marginTop: matches ? '12px' : '30px',
						}}
					>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<Box style={{ width: '100%' }}>
								<Typography
									style={{
										fontFamily: 'Lucida-Regular',
										color: '#fff',
										textAlign: 'justify',
										fontSize: matches ? '7px' : '16px',
									}}
								>
									Nossa sede está localizada em Rua Catauai, Quadra 37,
									Lote 12 S/N, Casa 2.
								</Typography>
								<Typography
									style={{
										fontFamily: 'Lucida-Regular',
										color: '#fff',
										textAlign: 'justify',
										fontSize: matches ? '7px' : '16px',
									}}
								>
									Parque Amazonia. Goiânia - GO, 74840-130.
								</Typography>
								<Typography
									style={{
										fontFamily: 'Lucida-Regular',
										color: '#fff',
										textAlign: 'justify',
										fontSize: matches ? '7px' : '16px',
									}}
									component={Link}
									to={{ pathname: APP_CONFIG.linkGoogleMaps }}
									target="_blank"
								>
									Clique aqui para se redirecionar ao mapa.
								</Typography>
							</Box>

							<Box
								style={{
									height: matches ? '112px' : '285px',
									width: matches ? '256px' : '640px',
									marginTop: '20px',
									borderRadius: 27,
								}}
							>
								<GoogleMapReact
									bootstrapURLKeys={{
										key: 'AIzaSyCvUPwRyI7PScl0mjotdy9TKjrQlKYTa_Y',
									}}
									defaultCenter={defaultProps.center}
									defaultZoom={defaultProps.zoom}
								>
									<LocationOnIcon
										style={{ color: 'red' }}
										lat={-16.732960436894135}
										lng={-49.26833427744318}
										text="My Marker"
									/>
								</GoogleMapReact>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				ref={fourthRef}
				style={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					backgroundColor: APP_CONFIG.mainCollors.backgrounds,
				}}
			>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Corbel-Bold',
							fontSize: matches ? '20px' : '50px',
							color: APP_CONFIG.mainCollors.tertiary,
						}}
					>
						CONTATO
					</Typography>
					<Box
						style={{
							display: 'flex',
							alignSelf: 'center',
							width: '70%',
							marginTop: matches ? '4px' : '30px',
						}}
					>
						<Typography
							style={{
								fontFamily: 'Lucida-Regular',
								color: APP_CONFIG.mainCollors.primary,
								textAlign: 'center',
								fontSize: matches ? '8px' : '20px',
							}}
						>
							Para informações ou contato com a Cosmo Contabilidade,
							utilize o botão abaixo. Nossa equipe de atendimento
							retornará a sua mensagem o mais breve possível.
						</Typography>
					</Box>
					<form ref={form}>
						<Box
							style={{
								padding: '20px',
							}}
						>
							<Grid
								spacing={2}
								style={{ marginTop: '20px', display: 'flex' }}
							>
								<Grid item sm={12} xs={12}>
									<TextField
										variant="outlined"
										name="user_name"
										id="user_name"
										fullWidth
										placeholder="Nome completo"
									/>
								</Grid>
							</Grid>
							<Grid
								spacing={2}
								style={{ marginTop: '20px', display: 'flex' }}
							>
								<Grid item sm={6} xs={12}>
									<InputMask mask="(99) 99999-9999">
										{() => (
											<TextField
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												name="user_tel"
												id="user_tel"
												fullWidth
												placeholder="Telefone / Whatsapp"
												required
												type="tel"
											/>
										)}
									</InputMask>
								</Grid>
								<Grid
									item
									sm={6}
									xs={12}
									style={{ marginLeft: '10px' }}
								>
									<TextField
										name="user_email"
										id="user_email"
										fullWidth
										variant="outlined"
										placeholder="E-mail"
									/>
								</Grid>
							</Grid>

							<Grid spacing={2} style={{ marginTop: '20px' }}>
								<Grid item sm={12} xs={12}>
									<TextField
										name="message"
										id="message"
										fullWidth
										variant="outlined"
										placeholder="Mensagem"
										multiline
										InputProps={{
											classes: {
												root: classes.inputRoot,
											},
										}}
									/>
								</Grid>
							</Grid>
							<Box style={{ marginTop: '25px' }}>
								<CustomButton
									color="primary"
									onClick={(e) => {
										handleEnviar(e);
									}}
								>
									<Typography style={{ fontFamily: 'Corbel-Bold' }}>
										ENVIAR
									</Typography>
								</CustomButton>
							</Box>
						</Box>
					</form>
				</Box>
			</Box>
			<Divider
				style={{
					width: '100%',
					height: '0.01rem',
					backgroundColor: APP_CONFIG.mainCollors.primary,
					marginTop: matches ? '8px' : '20px',
				}}
			/>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '10px',
				}}
			>
				<Box
					style={{
						display: 'flex',
						alignSelf: 'center',
						width: '400px',
					}}
				>
					<Box>
						<Box>
							<img
								src={APP_CONFIG.assets.logo}
								alt="logo"
								style={{ width: matches ? '200px' : '200px' }}
							/>
						</Box>
					</Box>

					<Box
						style={{
							display: 'flex',
							alignItems: 'start',
							flexDirection: 'column',
							marginLeft: '15px',
						}}
					>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<LocalPhoneOutlinedIcon
								style={{
									marginRight: '5px',
									color: APP_CONFIG.mainCollors.primary,
								}}
							/>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontFamily: 'Lucida-Regular',
									fontSize: matches ? '8px' : '20px',
								}}
							>
								{APP_CONFIG.telefone}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<MailOutlineIcon
								style={{
									marginRight: '5px',
									color: APP_CONFIG.mainCollors.primary,
								}}
							/>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontFamily: 'Lucida-Regular',
									fontSize: matches ? '8px' : '20px',
								}}
							>
								{APP_CONFIG.email}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<InstagramIcon
								style={{
									marginRight: '5px',
									color: APP_CONFIG.mainCollors.primary,
								}}
							/>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontFamily: 'Lucida-Regular',
									fontSize: matches ? '8px' : '20px',
								}}
							>
								{APP_CONFIG.instagram}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<Divider
				style={{
					marginTop: '10px',
					width: '100%',
					height: '0.01rem',
					backgroundColor: APP_CONFIG.mainCollors.primary,
				}}
			/>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '63px',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '10px',
				}}
			>
				<Typography
					style={{
						color: APP_CONFIG.mainCollors.primary,
						textAlign: 'center',
					}}
				>
					2023 COSMO CONTABILIDADE. Todos os Direitos Reservados
				</Typography>
				<Typography
					style={{
						color: APP_CONFIG.mainCollors.tertiary,
						textAlign: 'center',
					}}
				>
					Designed by Treemage EC
				</Typography>
			</Box>
		</Box>
	);
};

export default Dashboard;
