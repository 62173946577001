import faviconCosmo from '../assets/cosmoAssets/favicon/favicon.ico';
import logoCosmo from '../assets/cosmoAssets/logoCosmo.svg';
import backgroundCosmo1 from '../assets/cosmoAssets/backgroundCosmo1.png';
import backgroundCosmo2 from '../assets/cosmoAssets/backgroundCosmo2.png';
import backgroundCosmo3 from '../assets/cosmoAssets/backgroundCosmo3.png';
import backgroundCosmo4 from '../assets/cosmoAssets/backgroundCosmo4.png';
import sliderCosmo1 from '../assets/cosmoAssets/sliderCosmo1.png';
import sliderCosmo2 from '../assets/cosmoAssets/sliderCosmo2.png';
import sliderCosmo3 from '../assets/cosmoAssets/sliderCosmo3.png';
import sliderCosmo4 from '../assets/cosmoAssets/sliderCosmo4.png';
import sliderCosmo5 from '../assets/cosmoAssets/sliderCosmo5.png';
import sliderCosmo6 from '../assets/cosmoAssets/sliderCosmo6.png';
import logoParceiroCosmo1 from '../assets/cosmoAssets/logosParceiros/logo1.png';
import logoParceiroCosmo2 from '../assets/cosmoAssets/logosParceiros/logo2.png';
import logoParceiroCosmo3 from '../assets/cosmoAssets/logosParceiros/logo3.png';
import logoParceiroCosmo4 from '../assets/cosmoAssets/logosParceiros/logo4.png';
import fotoLocalCosmo from '../assets/cosmoAssets/fotoLocal.png';

export const APP_CONFIG = {
	cosmo: {
		name: 'Cosmo Contabilidade',
		telefone: '+55 62 3954-2758',
		email: 'adm.cosmocontabilidade@gmail.com',
		horario: 'Segunda à Sexta-feira das 8h às 18h',
		instagram: '@cosmocontabilidade',
		linkInstagram:
			'https://instagram.com/cosmocontabilidade?igshid=MzRlODBiNWFlZA==',
		linkGoogleMaps: 'https://maps.app.goo.gl/ueKt1o9TZHiQyyFt9',

		mainCollors: {
			primary: '#0570b7',
			secondary: '#d6ba73',
			tertiary: '#414954',
			backgrounds: '#fff9ed',
			cards: 'rgba(5, 112, 183, .5)',
		},
		theme: {
			typography: {
				fontFamily: 'Lucida-Regular-Regular',
				/* fontWeight: 'bold', */

				h1: {
					fontFamily: 'Lucida-Regular-Regular',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Lucida-Regular-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Lucida-Regular-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Lucida-Regular-Regular',
				},
			},
			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#0570b7',
					light: '#EDEDF4',
				},
				secondary: {
					main: '#fff',
					light: '#fff',
				},
				tertiary: {
					main: '#fff',
					light: '#fff',
				},
			},
			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Lucida-Regular',

						color: '#0570b7',
					},
					label: {
						color: 'black',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiOutlinedInput: {
					root: {
						backgroundColor: 'rgba(255, 249, 237, .57)',
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #0570b7, #0570b7);',
			},
		},
		assets: {
			favicon: faviconCosmo,
			logo: logoCosmo,
			background1: backgroundCosmo1,
			background2: backgroundCosmo2,
			background3: backgroundCosmo3,
			background4: backgroundCosmo4,
			slider1: sliderCosmo1,
			slider2: sliderCosmo2,
			slider3: sliderCosmo3,
			slider4: sliderCosmo4,
			slider5: sliderCosmo5,
			slider6: sliderCosmo6,
			logoParceiro1: logoParceiroCosmo1,
			logoParceiro2: logoParceiroCosmo2,
			logoParceiro3: logoParceiroCosmo3,
			logoParceiro4: logoParceiroCosmo4,
			fotoLocal: fotoLocalCosmo,
		},
	},
}[process.env.REACT_APP_FRONT_APP || 'cosmo'];
