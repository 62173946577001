import { Route, Switch } from 'react-router';
import React from 'react';
import Dashboard from '../pages/Dashboard/Dashboard';

const Routes = () => {
	return (
		<Switch>
			<Route path="/" exact component={Dashboard} />
		</Switch>
	);
};

export default Routes;
